import React from 'react'
import PropTypes from 'prop-types'
import {kebabCase} from 'lodash'
import {Helmet} from 'react-helmet'
import {graphql, Link} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import {GatsbyImage, getSrc} from "gatsby-plugin-image";

export const Fragen6Template = ({
                                  content,
                                  contentComponent,
                                  description,
                                  featuredimage,
                                  tags,
                                  title,
                                  amazonlink,
                                  productimage,
                                  fragen,
                                }) => {
  const PostContent = contentComponent || Content
  const imageStyle = {width: '120px', margin: "auto"}
  const featuredimageSrc = getSrc(featuredimage) || featuredimage;

  return (
    <section className="section">
      <Helmet titleTemplate="%s | Blog">
        <title>{`${title}`}</title>
        <meta
          name="description"
          content={`${description}`}
        />
        <meta property="og:image" content={featuredimageSrc}/>
        <script type="application/ld+json">{`
                {
                "@context": "http://schema.org",
                "@type": "FAQPage",
                "mainEntity": [${fragen.map((fragenObj) => `{
                    "@type": "Question",
                    "name": "${fragenObj.frage}",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "${fragenObj.text.split('<br />').join('').split('  ').join(' ')}"
                    }
                  }`)}
                ],
                "inLanguage": "de-DE"
                }
              `}</script>
      </Helmet>
      <div className="container content" style={{maxWidth: "1024px"}}>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <div className="mb-5">
              <GatsbyImage image={featuredimage.childImageSharp.gatsbyImageData}/>
            </div>
            <div className="content">
              {fragen.map((fragenObj) => (
                <>
                  <h2 className="numbering">
                    {fragenObj.frage}
                  </h2>
                  {fragenObj.text.split("<br />").map(ele => {
                    return <p>{ele}</p>
                  })}
                </>
              ))}
            </div>
            <PostContent content={content}/>
            {amazonlink === null ?
              null : (
                <div>
                  <div style={{marginTop: `4rem`}}>
                    <a href={amazonlink} rel="nofollow">
                      <GatsbyImage style={imageStyle} image={productimage.childImageSharp.gatsbyImageData}/>
                    </a>
                  </div>
                  <div style={{marginTop: `1rem`, textAlign: 'center'}}>
                    <a className="btn" href={amazonlink} rel="nofollow">Auf Amazon ansehen*</a>
                  </div>
                </div>
              )}
            {tags && tags.length ? (
              <div style={{marginTop: `4rem`}}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

Fragen6Template.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  amazonlink: PropTypes.string,
  productimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fragen: PropTypes.array,
}

const Fragen6 = ({data}) => {
  const {markdownRemark: post} = data

  return (
    <Layout>
      <Fragen6Template
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        featuredimage={post.frontmatter.featuredimage}
        amazonlink={post.frontmatter.amazonlink}
        productimage={post.frontmatter.productimage}
        fragen={post.frontmatter.fragen}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

Fragen6.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Fragen6

export const pageQuery = graphql`
  query Fragen6ByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
            childImageSharp{
              gatsbyImageData(width: 1024, quality: 70)
            }        
        }
        amazonlink
        productimage {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 120, quality: 70)
          }
        }
        fragen {
          frage
          text        
        }
        tags
      }
    }
  }
`
